<template>
  <div>
    <NavHeader />
    <NavHeaderMobile />
    <div class="wrapper">
      <!-- // Example to embed widget in iframe for testnet -->
      <iframe frameborder="0"
        :src="`${baseUrl}/?widgetId=${widgetId}&tradeType=${tradeType}`"></iframe>
    </div>
    <NewFooter />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import NewFooter from '@/components/NewFooter.vue';

export default {
  name: 'Home',
  components: { NavHeader, NavHeaderMobile, NewFooter },
  data() {
    return {
      widgetId: 'b2Fta2k2TUE',
      tradeType: 'buy',
      baseUrl: 'https://openc.pro/widget-page',
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  display: flex;
  padding-top: 80px;
  margin: auto;
  margin-bottom: 40px;
  // min-height: calc(100vh - 160px);
  min-height: calc(100vh + 160px);

  @media (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 0px;
  }
}

iframe {
  flex:1;
  height: auto;
  // width: 100%;
  // min-height: 600px;
}

.wrapper-left {
  width: 360px;
  height: 540px;
  margin-top: 30px;
  padding: 20px;
  background: var(--background-common);
  margin-right: 40px;
  box-shadow: 0px 0px 40px 0px rgba(0, 69, 255, 0.2);
  border-radius: 4px;

  @media (max-width: 960px) {
    margin: 30px auto 0;
  }
}

.wrapper-left-item {
  margin-bottom: 20px;
}

.wrapper-left-item-payment {
  margin-top: 40px;
}</style>
